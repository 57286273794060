import { fetch } from 'utils/fetch';
import { urlEncodeBody } from 'utils/requests';
import { EditableNode } from 'views/PDFEditor/types';

export async function getMergedPdfDocumentByView(viewId: string) {
  const response = await fetch(`/documents/pdf_editor/external/${viewId}/merged`);
  const downloadUrl = URL.createObjectURL(await response.blob());
  return downloadUrl;
}

export async function getSignaturesExternal(
  viewId: string
): Promise<{
  documentId?: number;
  nodes: EditableNode[];
  role?: string;
  signature?: string;
  signed: boolean;
  nextDocument?: string;
  initials?: string;
}> {
  const response = await fetch(`documents/pdf_editor/external/${viewId}/signatures`);
  const data = await response.json();

  return {
    documentId: data.document_id,
    nodes: data.layer
      ? JSON.parse(data.layer).filter(
          item => ['signature', 'initials'].includes(item.type) && item.role === data.role_specific
        )
      : [],
    role: data.role_specific,
    signature: data.contact_person || data.name,
    signed: data.is_signed,
    nextDocument: data.next_document,
    initials: (data.contact_person || data.name)
      ?.split(' ')
      .map(chunk => chunk[0])
      .join('')
  };
}

export async function applySignature(
  viewId: string,
  values: {
    signature?: string;
    initials?: string;
  }
): Promise<void> {
  await fetch(`/documents/pdf_editor/external/${viewId}/sign`, {
    method: 'POST',
    body: urlEncodeBody({
      signature: values.signature,
      initials: values.initials
    })
  });
}
