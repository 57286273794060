import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Button, Icon } from 'components';
import SignContext from './context';

const DocumentNavigation = () => {
  const { document, documentDispatch } = useContext(SignContext);
  const { currentPage, totalPages } = document;

  return totalPages ? (
    <StyledDocumentNavigation className="document-navigation">
      <Button
        simple
        disabled={currentPage === 1}
        onClick={function(event){ documentDispatch({ type: 'SET_CURRENT_PAGE', value: currentPage - 1 || 1 }); documentDispatch({ type: 'IS_NAVIGATING', value: true })}}
      
      >
        <Icon name="arrow-simple" />
      </Button>
      {currentPage} / {totalPages}
      <Button
        simple
        disabled={currentPage === totalPages}
        onClick={function(event){ documentDispatch({ type: 'SET_CURRENT_PAGE', value: currentPage + 1 < totalPages ? currentPage + 1 : totalPages }); documentDispatch({ type: 'IS_NAVIGATING', value: true })}}
        >
        <Icon name="arrow-simple" />
      </Button>
    </StyledDocumentNavigation>
  ) : null;
};

export default DocumentNavigation;

const StyledDocumentNavigation = styled.div``;
